import React, { useState, useEffect } from "react"
import {
  Box,
  Text,
  Columns,
  Column,
  Button,
  ContentBlock,
  Stack,
  Inline,
  Icon,
  Divider,
  ScrollBox,
} from "@64labs/bowline-design-system"
import { Link, graphql } from "gatsby"
import Image from "gatsby-image"
import Layout from "../components/layout"
import SEO from "../components/seo"
import "./index.css"
import "../svg/icon-chat.svg"
import "../svg/icon-bubbles.svg"
import "../svg/icon-e-flask.svg"
import "../svg/icon-chart.svg"
import "../svg/icon-design.svg"
import "../svg/icon-gears.svg"
import { useSpring, animated } from "react-spring"

const HeroImage = ({ currentSlide, item, index }) => {
  const style = useSpring({
    opacity: currentSlide === index ? 1 : 0,
    config: { duration: 500 },
  })
  return (
    <animated.div style={style}>
      <Box
        position={index !== currentSlide && "absolute"}
        as={Link}
        aria-label={item.title}
        to={item.heroUrl}
      >
        <Box display={["block", "block", "none"]}>
          <Image
            loading="eager"
            fadeIn={false}
            alt={item.mobileImage.description}
            fluid={{
              ...item.mobileImage.fluid,
              aspectRatio: 450 / 250,
              media: "(max-width: 767px)",
            }}
          />
        </Box>
        <Box display={["none", "none", "block"]}>
          <Image
            loading="eager"
            fadeIn={false}
            alt={item.desktopImage.description}
            fluid={{ ...item.desktopImage.fluid, media: "(min-width: 768px)" }}
          />
        </Box>
      </Box>
    </animated.div>
  )
}

const IndexPage = ({ data }) => {
  const [currentSlide, setCurrentSlide] = useState(0)
  const [slideInterval, setSlideInterval] = useState(null)

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSlide(currentSlide =>
        currentSlide === data.heroes.item.length - 1 ? 0 : currentSlide + 1
      )
    }, 5000)
    setSlideInterval(interval)
    return () => clearInterval(interval)
  }, [data.heroes.item.length])

  return (
    <Layout>
      <SEO title="Mobile and Web Solutions" />

      <Box
        position="relative"
        style={{
          zIndex: 100,
          background: data.heroes.item[currentSlide].backgroundColor,
        }}
      >
        <ContentBlock position="relative" paddingX={["none", "gutter"]}>
          {data.heroes.item.map((_item, index) => {
            return (
              <HeroImage
                currentSlide={currentSlide}
                item={_item}
                index={index}
              />
            )
          })}
          <Box
            display="flex"
            justify="center"
            position="absolute"
            className="c-heroes__dot-group"
          >
            {data.heroes.item.map((_item, index) => (
              <Button
                style={{ width: 48, height: 48 }}
                weight="weak"
                display="flex"
                justify="center"
                align="flex-end"
                onClick={() => {
                  setCurrentSlide(index)
                  clearInterval(slideInterval)
                }}
              >
                <Box
                  aria-label="carousel dot"
                  padding="none"
                  className={`hero__dot ${
                    currentSlide === index ? "hero__dot--selected" : ""
                  }`}
                  
                />
              </Button>
            ))}
          </Box>
        </ContentBlock>
      </Box>
      {/* <Box
        style={{
          height: 17,
          background: `url(${data.featureBarImage.childImageSharp.fluid.src})`,
        }}
        marginBottom={["none", "xsmall"]}
      /> */}
      <Box paddingY="xxsmall">
        <ScrollBox scrollX>
          <Inline
            wrap={false}
            paddingX="gutter"
            space={["gutter", "gutter", "xlarge"]}
            justify={["flex-start", "flex-start", "flex-start", "center"]}
          >
            {data.featuredClients.item.map(
              ({ clientName, clientLogo }, index) => {
                const style = {}
                const name = clientName
                  .replace(/ /g, "-")
                  .replace(/'/g, "")
                  .toLowerCase()

                // Pandora logo sits lower than the rest - remove once fixed
                if (name === "pandora") {
                  style.paddingBottom = 8
                }

                return (
                  <Text
                    className="home__featured-client-image"
                    as={Link}
                    to={`/work/${name}`}
                    key={`home-featured-client-${index}`}
                    aria-label={name}
                    baseline={false}
                    display="flex"
                    style={{
                      width: "100%",
                      height: "100%",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Box padding="xxsmall" style={{ width: 142 }}>
                    <Image
                        alt={clientLogo.description}
                        imgStyle={style}
                        fluid={clientLogo.fluid}
                      />
                    </Box>
                  </Text>
                )
              }
            )}
          </Inline>
        </ScrollBox>
      </Box>

      <Box
        background="white"
        style={{
          background:
            "-webkit-linear-gradient(top, #F5F5F5, #f5f5f5 70%, #fff)",
        }}
      >
        <ContentBlock
          paddingBottom={["large", "large", "xlarge"]}
          paddingTop={["gutter", "gutter", "xlarge"]}
        >
          <Columns
            cols={[1, 1, 3, 3]}
            colGap={["none", "large", "xxlarge"]}
            rowGap="large"
          >
            <Stack align="center" space="small">
              <Stack space="small" align="center">
                <Icon name="icon-chat" size="xxlarge" />
                <Text as="h3" heading size="large" tone="brandAccent">
                  Understand
                </Text>
                <Text align="center">
                  We listen before we speak. All our solutions are based on
                  business insight and experience.
                </Text>
              </Stack>
              <Button as={Link} to="/about" size="small" weight="strong">
                more about us
              </Button>
            </Stack>
            <Stack align="center" space="small">
              <Stack space="small" align="center">
                <Icon name="icon-bubbles" size="xxlarge" />
                <Text as="h3" heading size="large" tone="brandAccent">
                  Implement
                </Text>
                <Text align="center">
                  We can choose from a full range of technologies to solve your
                  mobile, tablet and desktop challenges.
                </Text>
              </Stack>
              <Button as={Link} to="/services" size="small" weight="strong">
                our services
              </Button>
            </Stack>
            <Stack align="center" space="small">
              <Stack space="small" align="center">
                <Icon name="icon-e-flask" size="xxlarge" />
                <Text as="h3" heading size="large" tone="brandAccent">
                  Support
                </Text>
                <Text align="center">
                  Launching a site is just the start. A real partnership grows
                  from a constant effort to improve.
                </Text>
              </Stack>
              <Button size="small" as={Link} to="/work" weight="strong">
                how we work
              </Button>
            </Stack>
          </Columns>
        </ContentBlock>
      </Box>

      <Box background="subtleLight" position="relative" overflow="hidden">
        <Box
          position="absolute"
          size="xlarge"
          background="white"
          style={{
            top: -34,
            left: "50%",
            transformOrigin: "top left",
            transform: "rotate(45deg)",
          }}
        />
        <Box>
          <ContentBlock paddingY="xxlarge">
            <Stack align="center" space="gutter">
              <Text as="h1" tone="brand" heading size="xlarge" align="center">
                We take the guesswork out of mobile and web
              </Text>
              <Text align="center" size="large">
                You make better decisions when you have good information. We
                have been working in this space for years and know what works.
                We know what support you are going to need to stay at the
                cutting edge.
              </Text>
            </Stack>
          </ContentBlock>
          <ContentBlock>
            <Divider />
          </ContentBlock>
          <ContentBlock paddingY="large">
            <Columns cols={4}>
              <Column span={[4, 4, 2, 1]}>
                <Stack space="small">
                  <Inline justify="center" space="xsmall">
                    <Icon name="icon-chart" />
                    <Text
                      as="h2"
                      heading
                      weight="medium"
                      tone="brandAccentAccessible"
                    >
                      Strategy
                    </Text>
                  </Inline>
                  <Text align="center">
                    If you don’t know where you’re going, every road feels like
                    it could be the right one. We work with you to translate
                    your business problems into smart solutions.
                  </Text>
                </Stack>
              </Column>
              <Column span={[4, 4, 2, 1]}>
                <Stack space="small">
                  <Inline justify="center" space="xsmall">
                    <Icon name="icon-design" />
                    <Text
                      as="h2"
                      heading
                      weight="medium"
                      tone="brandAccentAccessible"
                    >
                      Design
                    </Text>
                  </Inline>
                  <Text align="center">
                    A great user experience is the key to creating a lasting
                    impression with your customers. It’s not just about pretty
                    pictures but how well a site works.
                  </Text>
                </Stack>
              </Column>
              <Column span={[4, 4, 2, 1]}>
                <Stack space="small">
                  <Inline justify="center" space="xsmall">
                    <Icon name="icon-gears" />
                    <Text
                      as="h2"
                      heading
                      weight="medium"
                      tone="brandAccentAccessible"
                    >
                      Development
                    </Text>
                  </Inline>
                  <Text align="center">
                    Our development process is collaborative and transparent.
                    Our teams are experienced in every aspect of mobile and web
                    development.
                  </Text>
                </Stack>
              </Column>
              <Column span={[4, 4, 2, 1]}>
                <Stack space="small">
                  <Inline justify="center" space="xsmall">
                    <Icon name="icon-chat" />
                    <Text
                      as="h3"
                      heading
                      weight="medium"
                      tone="brandAccentAccessible"
                    >
                      Partnership
                    </Text>
                  </Inline>
                  <Text align="center">
                    We don’t kiss and run. We judge success on the quality of
                    our relationships, not the quantity, and we do whatever it
                    takes to make you look great.
                  </Text>
                </Stack>
              </Column>
            </Columns>
          </ContentBlock>
        </Box>
      </Box>
    </Layout>
  )
}

export const query = graphql`
  query {
    heroes: contentfulList(title: { eq: "Home Hero Order" }) {
      item {
        ... on ContentfulHomeHero {
          title
          backgroundColor
          heroUrl
          desktopImage {
            description
            fluid(maxWidth: 1200, quality: 100) {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
          }
          mobileImage {
            description
            fluid(maxWidth: 450, quality: 100) {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
          }
        }
      }
    }
    featuredClients: contentfulList(title: { eq: "Featured Clients Order" }) {
      id
      item {
        ... on ContentfulClientWork {
          id
          clientName
          clientLogo {
            description
            fluid(
              maxWidth: 200
              maxHeight: 100
              quality: 100
              resizingBehavior: PAD
            ) {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
          }
        }
      }
    }
    featureBarImage: file(relativePath: { eq: "feature_bar_bckgrd.png" }) {
      childImageSharp {
        fluid(maxWidth: 4) {
          src
        }
      }
    }
  }
`

export default IndexPage
